import React,{useEffect, useState} from 'react'
import {useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import { TailSpin } from "react-loader-spinner";
import {Box} from "@mui/material";
import AppExtensionsSDK from "@pipedrive/app-extensions-sdk";
import { SERVER_BASE_URL } from "../../constants.js";

function AuthRedirect() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const exchangeToken = searchParams.get("token");
    const appPlatform=searchParams.get("platform");
        
    const signIn = useSignIn();

    const intializeSdk = async () => {
        const sdk = await new AppExtensionsSDK({
          identifier:  searchParams.get("id"),
        }).initialize({ size: { height: 700, width: 800 } });
    };

    const authenticate = async () => {
        if (exchangeToken != null) {
            try {
                const response = await axios.post(
                    `${SERVER_BASE_URL}/sharedInbox/auth/authExchange`,
                    {},
                    { headers: { exchangeToken: exchangeToken } }
                );
                if(response.status===200){
                    if(signIn({
                        auth:{
                          token:response.data?.authToken,
                          type:'Bearer',
                        },
                        userState: response.data?.userState,
                        isSignIn: true,
                        isUsingRefreshToken: false,
                    })){
                      const currentParams = new URLSearchParams(searchParams.toString());
                      currentParams.delete('token');
                      currentParams.set('view', 'iframe');
                      navigate(`/conversation?${currentParams.toString()}`);
                    }
                }
                else{
                    navigate(`/login`);
                }
            } catch (error) {
                console.error('Error authenticating exchange token:', error);
            }
        }
    };


    useEffect(() => {
        if(appPlatform==="pipedrive"){
            console.log("Initializing Pipedrive Instance")
            intializeSdk();
        }
        if(exchangeToken!==null && exchangeToken!==undefined){
            console.log("Authenticating")
            authenticate();
        }
    }, [appPlatform,exchangeToken]);
    return (
        <Box
            sx={{
                width: "100vw",
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <TailSpin
                height="60"
                width="60"
                color="#FFE6CB"
            />
        </Box>
    )
}

export default AuthRedirect